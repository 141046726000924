import { HTTP } from "@/service/http-common";
import axios from "axios";
import store from "@/store";

// initial state
const state = () => ({
   
});

// getters
const getters = { };

// actions
const actions = {
  
   itemLists(_, shopId) {

      return new Promise((resolve, reject) => {
   
         HTTP.get("item/lists/" + shopId)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },

   itemCreate(_, data) {

      return new Promise( (reslove, reject) => {

         const instance = axios.create();

         const config = {
            headers: {
              Authorization: store.getters["auth/getToken"]
            }
          };

         instance.post('item/create', data,  config)
         .then( (res) => {
            reslove(res);
         })
         .catch((error) => {
            reject(error.response.data);
         });

      })

   },

   itemUpdate(_, data) {

      return new Promise( (reslove, reject) => {

         const instance = axios.create();

         const config = {
            headers: {
              Authorization: store.getters["auth/getToken"]
            }
          };

         instance.post('item/update', data, config) 
         .then( (res) => {
            reslove(res);
         })
         .catch((error) => {
            reject(error);
         });

      });

   }, 

   itemDelete(_, id) {

      return new Promise( (reslove, reject) => {

         HTTP.delete('item/delete/' + id) 
         .then( (res) => {
            reslove(res);
         })
         .catch((error) => {
            reject(error);
         });

      });

   }

};

// mutations
const mutations = { };

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
