import { HTTP } from "@/service/http-common";
// initial state
const state = () => ({
   is_auth: false,
   token: "",
   user: {
      id: "",
      name: "",
      email: "",
      shopId: "",
      shopName: "",
      shopAddress: "",
      shopPhone: "",
      taxStatus: "",
      role: "",
      permissions: [],
      joinDate: "",
   },
  
});

// getters
const getters = {
   isAuth(state) {
      return state.is_auth;
   },
   getToken(state) {
      return state.token;
   },
   getAuthName(state) {
      return state.user.name;
   },
   getAuthId(state) {
      return state.user.id;
   },
   getAuthEmail(state) {
      return state.user.email;
   },
   getAuthShopId(state) {
      return state.user.shopId;
   },
   getShopName(state) {
      return state.user.shopName;
   },
   getAddress(state) {
      return state.user.shopAddress;
   },
   getPhone(state) {
      return state.user.shopPhone;
   },
   getTax(state) {
      return state.user.taxStatus;
   },
   getRole(state) {
      return state.user.role;
   },
   getPermissions(state) {
      return state.user.permissions;
   },
   getJoinDate(state) {
      return state.user.joinDate;
   }
};

// actions
const actions = {
   login({ commit },input) {
      return new Promise((resolve, reject) => {
         const bodyParameter = {
            email: input.email,
            password: input.password,
         };

         HTTP.post("authenticate", bodyParameter)
            .then((response) => {

               commit("setToken", "Bearer " + response.data.data.token);
               commit("setAuth", true);
               commit("setAuthUser", response.data.data.user);
               
               resolve(response.data.data.user);
            })
            .catch( error => reject(error.response.data.message));
      });
   },
   logout({ commit }) {
      return new Promise((resolve, reject) => {

         const user = {
            id: "",
            name: "",
            email: "",
            shopId: "",
            shopName: "",
            shopAddress: "",
            shopPhone: "",
            taxStatus: "",
            permissions: [],
         };
   
         HTTP.post("logout", "")
            .then((response) => {

               commit("setToken", "");
               commit("setAuth", false);
               commit("setAuthUser", user);

               resolve(response);
            })
            .catch((error) => {

               commit("setToken", "");
               commit("setAuth", false);
               commit("setAuthUser", user);
               
               reject(error.response.data.errors);
            });
      });
   },
   changePassword(_, input) {
      return new Promise((resolve, reject) => {
         const bodyParameter = {
            id: input.id,
            new_password: input.new_password,
         };

         HTTP.post("change-password-frontend", bodyParameter)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error.response.data.errors);
            });
      });
   },
};

// mutations
const mutations = {
   setToken(state, token) {
      state.token = token;
   },
   setAuth(state, payload) {
      state.is_auth = payload;
   },
   setAuthUser(state, payload) {
      
      state.user.id = payload.id;
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.shopId = payload.shop_id;
      state.user.shopName = payload.shop_name;
      state.user.shopAddress = payload.shop_address;
      state.user.shopPhone = payload.shop_phone;
      state.user.taxStatus = payload.taxStatus;
      state.user.role = payload.role;
      state.user.permissions = payload.permissions; 
      state.user.joinDate = payload.joinDate;
   },
   setTaxStatus(state, payload) {

      state.user.taxStatus = payload;

   }
  
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
