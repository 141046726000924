import { HTTP } from "@/service/http-common";

// initial state
const state = () => ({
   
});

// getters
const getters = { };

// actions
const actions = {
  
   addInvoice(_, data) {
    
      return new Promise( (reslove, reject) => {

         HTTP.post('add/invoice', data)
         .then( res => reslove(res))
         .catch( error => reject(error));

      });

   },

   invoiceList(_, status) {

      const data = {
         status,
      };

      return new Promise( (reslove, reject) => {

         HTTP.post('invoice/lists', data)
         .then( res => reslove(res))
         .catch( error => reject(error));
         
      });

   },

   updateInvoices(_, data) {

      return new Promise( (reslove, reject) => {

         HTTP.post('update/invoice', data)
         .then( res => reslove(res))
         .catch( error => reject(error));
         
      });

   },

   removeInvoice(_, id) {

      return new Promise( (reslove, reject) => {

         HTTP.get(`remove/invoice/${id}`)
         .then( res => reslove(res))
         .catch( error => reject(error));
         
      });

   },

};

// mutations
const mutations = { };

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
