import { createStore } from 'vuex';
import auth from "./modules/auth";
import category from "./modules/category";
import item from "./modules/item";
import invoice from "./modules/invoice";
import user from "./modules/user";
import paymentlist from "./modules/paymentlist";
import reorder from "./modules/reorder";
import setting from "./modules/setting";
import report from "./modules/report";
import language from "./modules/language";
import vuexPersistedstate from "vuex-plugin-persistedstate";

export default createStore({
  modules: {
    auth,
    category,
    item,
    invoice,
    user,
    paymentlist,
    reorder,
    setting,
    report,
    language
  },
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  plugins: [vuexPersistedstate()],
})
