import { HTTP } from "@/service/http-common";

// initial state
const state = () => ({
   
});

// getters
const getters = { };

// actions
const actions = {
  
   userLists() {

      return new Promise((resolve, reject) => {

        HTTP.get("user")
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },

   userCreate(_, data) {

      return new Promise((resolve, reject) => {
   
        const parameter = {
            name: data.name,
            email: data.email,
            password: data.password,
            shop_id: data.shop_id,
            role: data.role
        };

        HTTP.post("create-user", parameter)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },

   userUpdate(_, data) {
      return new Promise((resolve, reject) => {
   
         const parameter = {
            id: data.id,
            name: data.name,
            email: data.email,
            password: data.password,
            role: data.role
         };
 
         HTTP.put("user-edit", parameter)
             .then((response) => {
                resolve(response);
             })
             .catch((error) => {
                reject(error);
             });
       });
   }, 

   userDelete(_, id) {

      return new Promise((resolve, reject) => {
   
         HTTP.delete("user-delete/" + id )
             .then((response) => {
                resolve(response);
             })
             .catch((error) => {
                reject(error);
             });
       });

   }

};

// mutations
const mutations = { };

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
