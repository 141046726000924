export default {
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LogOut"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
  "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
  "reorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reorder"])},
  "user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User-Role"])},
  "payment-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment-Lists"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
  "sale-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale-Report"])},
  "total-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total-Items"])},
  "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total-Amount"])},
  "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total-Seller"])},
  "today-best-selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's best selling items"])},
  "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash"])},
  "banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banking"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
}