import { HTTP } from "@/service/http-common";

// initial state
const state = () => ({
   
});

// getters
const getters = { };

// actions
const actions = {
   getCategories(_, shopId) {

      return new Promise((resolve, reject) => {

         HTTP.get("category/lists/" + shopId)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   createCategory(_, data) {

      return new Promise((resolve, reject) => {

         const bodyParameter = {
            name: data.name,
            parentId: data.parentId,
            shopId: data.shopId
         };

         HTTP.post("category/create", bodyParameter)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   updateCategory(_, data) {

      return new Promise((resolve, reject) => {

         const bodyParameter = {
            id: data.id,
            name: data.name,
            parentId: data.parentId
         };

         HTTP.post("category/update", bodyParameter)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },
   removeCategory(_, id) {

      return new Promise((resolve, reject) => {

         HTTP.delete("/category/delete/" + id)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   }, 

   dashboardList() {

      return new Promise((resolve, reject) => {

         HTTP.get("/dashboard_data")
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });

   }
};

// mutations
const mutations = { };

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
