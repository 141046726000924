import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Toast from "vue-toastification";
import Preloader from "./components/PreloaderComponent.vue";

// prime vue datatable
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';


import '../src/assets/input.css'
import "vue-toastification/dist/index.css";

import i18n from './i18n';

router.beforeEach((to, from, next) => {
	
	// if (to.meta.requiresAuth && !store.getters["auth/isAuth"]) {
	// 	return { path: "/login" };
	// }

	// if (to.meta.isAuthRoute && store.getters["auth/isAuth"]) {
	// 	return { path: "/" };
	// }

	if (to.meta.requiresAuth && !store.getters["auth/isAuth"]) {
		next({ name: 'login'})
	}

	if (to.meta.isAuthRoute && store.getters["auth/isAuth"]) {
			next({ name: 'Dashboard'});
	}

	// if(!to.meta.isDashboard)
	// {
	// 	if(!to.meta.requirePermissions || !store.getters["auth/getPermissions"].includes(to.meta.permissions))
	// 	{
	// 		next({name: 'Dashboard'});
	// 	}
	// }
	
	if(to.meta.requirePermissions)
	{
		if(!store.getters["auth/getPermissions"].includes(to.meta.permissions))
		{
			next({name: 'Dashboard'});
		}
	}
		
	next();
	
});

// router.beforeEach( (to, from, next) => {
	
// 	if(!to.meta.isDashboard)
// 	{
// 		if(to.meta.requirePermissions && store.getters["auth/getPermissions"].includes(to.meta.permissions))
// 		{
// 			next();
// 		}else{
// 			next({name: 'Dashboard'});
// 		}
// 	}else{
// 		next();
// 	}
// });

const app = createApp(App);

app.use(PrimeVue)
app.use(store)
app.use(i18n)
app.use(router)
app.use(Toast, {
	transition: "my-custom-fade",
	maxToasts: 20,
	newestOnTop: true,
	position: "bottom-right",
	timeout: 3000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	closeButton: "button",
	icon: true,
	rtl: false,
 });
app.component('PreLoader', Preloader);
app.component('DataTable', DataTable);
app.component('ColumnComponent', Column);
app.component('InputText', InputText);


app.mount('#app')
