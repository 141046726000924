import { HTTP } from "@/service/http-common";

// initial state
const state = () => ({
   
});

// getters
const getters = { };

// actions
const actions = {
  
   reportLists() {

      return new Promise( (reslove, reject) => {

         HTTP.get('employee-report')
         .then( res => reslove(res))
         .catch( error => reject(error));
         
      });
  
   },

   searchByLogin(_, data) {

      return new Promise( (reslove, reject) => {

         HTTP.post('search/loginTime', data)
         .then( res => reslove(res))
         .catch( error => reject(error));
         
      });

   },

   salesReport(_, data) {

      return new Promise( (reslove, reject) => {

         HTTP.post('sales/report', data)
         .then( res => reslove(res))
         .catch( error => reject(error));
         
      });

   },

   getYear() {

      return new Promise( (reslove, reject) => {

         HTTP.get('getyear')
         .then( res => reslove(res))
         .catch( error => reject(error));
         
      });

   }

};

// mutations
const mutations = { };

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
