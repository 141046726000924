import { createI18n } from "vue-i18n";
import store from './store';

import en from './locales/en.json'
import mm from './locales/mm.json'

const i18n = createI18n({
  locale: store.getters["language/getLocale"],
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: {
    en,
    mm
  },
  legacy: false,
  globalInjection: true,
})

export default i18n;