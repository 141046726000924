import { HTTP } from "@/service/http-common";

// initial state
const state = () => ({
   
});

// getters
const getters = { };

// actions
const actions = {
   
    changeTaxStatus() {

      return new Promise((resolve, reject) => {

        HTTP.put("/changeTaxStatus")
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   }, 

};

// mutations
const mutations = { };

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
