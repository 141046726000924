// initial state
const state = () => ({
   locale: process.env.VUE_APP_I18N_LOCALE
});

// getters
const getters = {
   getLocale(state) {
      return state.locale;
   },
};

// actions
const actions = {
};

// mutations
const mutations = {
   setLocale(state, language) {
      state.locale = language;
   },
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
