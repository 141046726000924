import { HTTP } from "@/service/http-common";

// initial state
const state = () => ({
   
});

// getters
const getters = { };

// actions
const actions = {

   getPaymentLists() {

      return new Promise((resolve, reject) => {

        HTTP.get("paymentLists")
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },

   createPaymentType(_, data) {

      return new Promise((resolve, reject) => {

        const bodyParameter = {
            name: data.name,
            value: data.value,
            shop_id: data.shop_id,
        };

         HTTP.post("create-payment", bodyParameter)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   },

   removePaymentType(_, id) {

      return new Promise((resolve, reject) => {

         HTTP.delete("/remove-payment/" + id)
            .then((response) => {
               resolve(response);
            })
            .catch((error) => {
               reject(error);
            });
      });
   }
};

// mutations
const mutations = { };

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
