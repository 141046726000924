import { createWebHistory, createRouter } from "vue-router";
import shopRoutes from "./shop";
import pageNotFoundRoute from "./pageNotFound";

const baseRoutes = [
   {
      path: "/home",
      name: "shop-home",
      component: () => import("@/components/HomeComponent.vue"),
      meta: {
         requiresAuth: true,
      },
      children: shopRoutes,
   },
   {
      path: "/login",
      name: "login",
      component: () => import("@/components/LoginComponent.vue"),
      meta: { 
         isAuthRoute: true 
      },
   },
];

const routes = baseRoutes.concat(pageNotFoundRoute);

const router = createRouter({
   history: createWebHistory(),
   routes,
});

export default router;
