const shopRoutes = [
   {
      path: '/',
      name: 'Dashboard',
      component: () => import('@/components/DashboardComponent.vue'),
      meta: {
         title: 'eee',
         isDashboard: true,
      },
   },
   {
      path: '/shop',
      name: 'Shop',
      component: () => import('@/components/ShopComponent.vue'),
      meta: {
       requirePermissions: true,
       permissions: "can-sale",
      },
   },
   {
      path: '/category',
      name: 'Category',
      component: () => import('@/components/CategoryComponent.vue'),
      meta: {
         requirePermissions: true,
         permissions: "create-category",
      },
   },
   {
      path: '/item',
      name: 'Item',
      component: () => import('@/components/ItemComponent.vue'),
      meta: {
         requirePermissions: true,
         permissions: "create-item",
      },
   },
   {
      path: '/invoice/:status',
      name: 'Invoice',
      component: () => import('@/components/InvoiceComponent.vue'),
      props: true,
      meta: {
         requirePermissions: true,
         permissions: "show-invoice-list",
      },
   },
   {
      path: '/user-role',
      name: 'Userrole',
      component: () => import('@/components/RoleComponent.vue'),
      meta: {
         requirePermissions: false,
      },
   },
   {
      path: '/payment-list',
      name: 'PaymentList',
      component: () => import('@/components/PaymentListComponent.vue'),
      meta: {
         requirePermissions: true,
         permissions: "show-paymentlist",
      }
   },
   {
      path: '/print_invoice/:invoiceId',
      name: 'PrintInvoice',
      component: () => import('@/components/PrintInvoiceComponent.vue'),
      props: true
   },
   {
      path: '/reorder_items_list',
      name: 'ReorderList',
      component: () => import('@/components/ReorderComponent.vue'),
      meta: {
         requirePermissions: false,
      }
   },
   {
      path: '/setting',
      name: 'Setting',
      component: () => import('@/components/SettingComponent.vue'),
      meta: {
         requirePermissions: true,
         permissions: "available-change-setting",
      }
   },
   {
      path: '/profile',
      name: 'Profile',
      component: () => import('@/components/ProfileComponent.vue'),
      meta: {
         requirePermissions: false,
      }
   },
   {
      path: '/report',
      name: 'Report',
      component: () => import('@/components/EmployeeReportComponent.vue'),
      meta: {
         requirePermissions: true,
         permissions: "show-report",
      }
   },
   {
      path: '/sales/report',
      name: 'SalesReport',
      component: () => import('@/components/salesReportComponent.vue'),
      meta: {
         requirePermissions: true,
         permissions: "show-report",
      }
   }
];

export default shopRoutes;
