import { HTTP } from "@/service/http-common";

// initial state
const state = () => ({
   reorderLength: 0,
//    reorderItems: [],
});

// getters
const getters = { 
    itemLength(state) {
        return state.reorderLength;
    },
};

// actions
const actions = {

    reorderItems() {

        return new Promise((resolve, reject) => {
      
            HTTP.get("/reorderList")
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        });
    }

};

// mutations
const mutations = {

    setReorder(state, payload) {
        state.reorderLength = payload;
    }

 };

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations,
};
