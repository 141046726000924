export default {
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်ထွက်ရန်"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖော်ပြချက်"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အမျိုးအစားများ"])},
  "shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဈေးရောင်းရန်"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပစ္စည်းများ"])},
  "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြေစာ"])},
  "reorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကုန်လက်ကျန်"])},
  "user-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဝန်ထမ်းများ"])},
  "payment-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ငွေပေးချေမှုစာရင်း"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တင်ပြချက်"])},
  "sale-report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အရောင်းမှတ်တမ်း"])},
  "total-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စုစုပေါင်းပစ္စည်းများ"])},
  "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စုစုပေါင်းရောင်းရငွေ"])},
  "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အရောင်းကိုယ်စားလှယ်များ"])},
  "today-best-selling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ယနေ့အရောင်းရဆုံးပစ္စည်းများ"])},
  "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ငွေသား"])},
  "banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဘဏ်ကဒ်"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စုစုပေါင်း"])}
}