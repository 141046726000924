<template>

<router-view />

</template>

<script>

export default {
  
  methods: {
    redirectPage(param) {
      this.$router.push( '/'+ param);
    }
  }
}
</script>

<style>

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 50px;
}
</style>
