import axios from "axios";
import store from "@/store";
//import router from "@/router";

const baseUrl = process.env.VUE_APP_BASE_URL;

axios.defaults.baseURL = baseUrl;

axios.interceptors.request.use(config => 
{
  config.headers['Authorization'] = store.getters["auth/getToken"];
  config.headers['Content-Type'] = 'application/json';
  config.headers['Accept'] = 'application/json';

  return config;
});

export const HTTP = axios;
